body {
  background: $alfa;
}

.form-group > label {
  text-transform: uppercase;
}

.form-group > select {
  //-webkit-appearance: none;
}

.form-control {
  transition: background-color 0.15s ease-in-out;
  &:hover {
    background-color: white;
  }
}

hr {
  border-top: 1px solid white !important;
}

.container {
  max-width: 1300px !important;
}

.bordered-column {
  @media screen and (min-width:768px) {
    border-left: 3px solid;
    border-image-source: linear-gradient(to top, rgba(50,174,227,1) 0%, rgba(29,123,60,1) 100%);
    border-image-slice: 1;
  }
}

.btn {
  text-transform: uppercase;
  font-size: 14px !important;
  border: 0px solid !important;
}

.text-small {
  font-size: 12px;
}

.color-grey{
  color: $delta;
}

.multi-select {
  > option:checked {
    background: #6c757d;
  }
}

.error-message {
  color: #dc3545;
  margin: 5px 0 25px 0;
}

.portal-link {
  color: $gama;
  text-decoration: none;
  &:hover {
    color: #3ebaf0;
  }
}

.portal-link-dsc {
  color: #777877;
  text-align: center;
}

.align-text-left {
  text-align: left;
}

.clickable-text{
  background-color: white;
  border: none;
  color: $gama;
  width: 100%;
  &:hover {
    border: none;
    outline: none;
  }
  &:focus {
    border: none;
    outline: none;
  }
  &:active {
    border: none;
    outline: none;
  }
}

.your-gadgets-padding {
  padding: 10px;
}

.drop-down-icon {
  font-size: 1.5rem;
}

.collapsable-header {
  display: flex;
}

.mta-dsc {
  color: $delta;
}

.mta-button-text {
  margin: 0;
}

.mta-title {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-size: 23px;
  font-weight: 400;
}

.mta-row {
  padding: 10%;
  margin: 0!important;
}

.mta-col {
  padding: 0;
}

.secondary-button-body {
  background-color: white;
  color: $gama;
  padding: 4px;
}

.padding-2 {
  padding: 2px;
}
