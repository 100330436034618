.postcode-group {
  border-radius: 0;
  margin: 10px 0;
  max-height: 200px;
  overflow-y: auto;
  &__item {
    padding: 2px;
    font-size: 12px;
    border-radius: 0;
    background-color: #e8f0fd;
    cursor: pointer;
    color: black;
  }
  &__item:hover {
    background-color: #cde0ff;
  }
  &__item:nth-child(even){
    background-color: white;
  }
  &__item:nth-child(even):hover {
    background-color: #cde0ff;
  }
  &__item:last-child, &__item:first-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $gama;
  }

}

