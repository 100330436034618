.custom-card-body {
  background-color: white;
  height: 100%;
  &__subtitle {
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.card {
  .card-header {
    text-transform: uppercase;
    background: linear-gradient(to right, rgba(50,174,227,1) 0%, rgba(29,123,60,1) 100%);
    color: white;
    font-size: 20px;
    font-weight: bold;
  }
}

.custom-card {
  padding: 3px;
  border-radius: 3px;
  background: linear-gradient(to right, rgba(50,174,227,1) 0%, rgba(29,123,60,1) 100%);
  margin-bottom: 1rem;
  border: 0;
}


