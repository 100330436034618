.gadget-badge {
  display: flex;
  justify-content: space-between;
  padding: 0.4rem 0.5rem;
  margin-bottom: 1rem;
  font-size: 12px;
  border-radius: 0;
  background-color: $alfa;
  border: 1px solid $gama;

  &__content {
    display: flex;
    align-items: center;
  }
  &__btn {
    padding: 0;
  }
  &__icon {
    vertical-align: middle;
    color: $gama;
    font-size: 24px;
  }
}
