.cover-buttons {
  display: flex;
  align-items: center;
  height: 100%;
  &__button {
    margin: 10px;
    height: 100%;
    width: 50%;
    border-radius: 0;
  }
}
