.quote-display {
  &__small {
    font-size: 14px;
    margin-left: 5px;
    margin-right: 5px;
  }

  &__list {
    > li {
      font-size: 12px;
    }
  }
}
