.custom-input {
  border-radius: 3px !important;
  &:focus {
    border: 3px solid;
    background-color: white;
    border-image-source: linear-gradient(to right, rgba(50,174,227,1) 0%, rgba(29,123,60,1) 100%);
    border-image-slice: 1;
    outline: 0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
  }
}

