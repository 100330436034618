$alfa: white;
$beta: #39b549;
$gama: #32ADE3;
$delta: #8e908e;
$border: #a9a9a9;


$body-bg: $alfa;
$body-color: $gama;

// card
$card-bg: $alfa;
$card-cap-bg: $beta;
$card-border-radius: 0;
$card-border-width: 1px;
$card-border-color: $gama;

// forms

$input-bg: $alfa;
$input-color: $delta;
$input-border-radius: 3px;
$input-border-width: 2px;
$input-border-color: $border;
$input-placeholder-color: $delta;
